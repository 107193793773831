import { getHistoryStateValue, replaceHistoryState } from "../../utils/History";
import { PRODUCT_LIST_ID } from "../ProductList/constants";
import type { State } from "./SearchState";

export const saveCurrentScrollPosition = (currentPosition: number): void => {
  replaceHistoryState({ scrollPosition: currentPosition });
};

export const preventBrowserScrollingRestoration = (): void => {
  window.history.scrollRestoration = "manual";
};

export const scrollToPreviousPosition = (): void => {
  const scrollPosition = getHistoryStateValue("scrollPosition");
  if (scrollPosition !== undefined) {
    window.scrollTo({
      top: scrollPosition,
    });
  }
};

export const didUpdateSearchResult = (
  state: State,
  previousState?: State
): boolean =>
  previousState?.isFetchingNewSearchResult === true &&
  state.isFetchingNewSearchResult === false;

export const getScrollToPosition = (): number => {
  const productListElement = document.getElementById(PRODUCT_LIST_ID);
  if (productListElement === null) {
    return 0; // Fallback
  }
  const { bottom } = productListElement.getBoundingClientRect();
  // How much to scroll for products to be visible
  return bottom + window.pageYOffset - window.innerHeight;
};

export const shouldScrollToTopAfterFetchingNewData = (): boolean => {
  const productListElement = document.getElementById(PRODUCT_LIST_ID);
  if (productListElement === null) {
    return false;
  }
  const { bottom, height } = productListElement.getBoundingClientRect();
  const isProductListBiggerThanWindow = height > window.innerHeight;
  const isProductListBottomAboveWindowTop = bottom < 0;
  const isProductListBottomAboveWindowBottom = bottom - window.innerHeight < 0;
  return (
    (isProductListBiggerThanWindow && isProductListBottomAboveWindowBottom) ||
    isProductListBottomAboveWindowTop
  );
};

type ScrollToElementProps = {
  querySelector: string;
  duration?: number;
};

export const scrollToElement = ({
  querySelector,
  duration = 1000,
}: ScrollToElementProps): Promise<void> =>
  new Promise((resolve, reject) => {
    const element = document.querySelector(querySelector);
    if (element === null) {
      return reject();
    }
    const scrollTop = element.getBoundingClientRect().top + window.scrollY;
    void $([document.documentElement, document.body])
      .animate({ scrollTop }, duration)
      .promise()
      .done(() => resolve());
  });
