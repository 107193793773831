import type { ColourThemeData } from "@xxl/frontend-api";
import React from "react";
import { useTranslations } from "../../../contexts/Translations/TranslationsContext";
import { xxlTheme } from "../../../styles/xxl-theme";
import type { MultiChannelAvailability } from "../../../utils/data-types";
import { NewRibbon } from "../NewRibbon/NewRibbon";
const { colors } = xxlTheme;

const CHANNEL_THEME = {
  ONLINE: {
    backgroundColour: colors.xxlWebBlack,
    foregroundColour: colors.xxlWhite,
  },
  STORE: {
    backgroundColour: colors.xxlWhite,
    foregroundColour: colors.xxlWebBlack,
  },
};

export const channelTagTestId = "channel-tag";

type ChannelTagProps = {
  multichannelAvailability: string[] | MultiChannelAvailability[];
  isPDPImage?: boolean;
};

export const ChannelTag = ({
  multichannelAvailability = [],
  isPDPImage = false,
}: ChannelTagProps) => {
  const { t } = useTranslations();

  const showChannelTag = multichannelAvailability.length === 1;
  const [availability] = multichannelAvailability;
  const className = isPDPImage ? "product__images-label" : "";
  const isOnline = availability === "ONLINE";
  const label = isOnline
    ? t("product.available.online")
    : t("product.available.store");
  const colorTheme: ColourThemeData = !isOnline
    ? CHANNEL_THEME.STORE
    : CHANNEL_THEME.ONLINE;
  return showChannelTag ? (
    <NewRibbon
      variant="WithColorTheme"
      dataTestid={channelTagTestId}
      colorTheme={colorTheme}
      label={label}
      direction="right"
      className={className}
    />
  ) : null;
};
